<template>
  <div>
    <div class="form-group">
      <el-form-item label="Filled By">
        <el-select class="filledby" v-model="field.filled_by">
          <el-option
            v-for="(option,index) of getFilledByOptions"
            :key="index"
            :value="option.value"
            :label="option.name"
          ></el-option>
        </el-select>
      </el-form-item>
    </div>
  </div>
</template>

<script>
export default {
  name:"templates-formComponents-FieldFilledBy",
  props: ["field"],
  computed: {
    getFilledByOptions() {
      if (this.field.input_type == "SIGNATURE") {
        return [
          { name: "SENDER", value: "SENDER" },
          { name: "RECEIVER", value: "RECEIVER" },
          { name: "SENDER OR RECEIVER", value: "SENDER_OR_RECEIVER" }
        ];
      }
      return this.fieldFilledByOptions;
    }
  },
  data() {
    return {
      fieldFilledByOptions: [
        { name: "SENDER", value: "SENDER" },
        { name: "RECEIVER", value: "RECEIVER" },
        { name: "SENDER OR RECEIVER", value: "SENDER_OR_RECEIVER" }
      ]
    };
  }
};
</script>

<style lang="scss">
.filledby {
  width: 45%;
}
</style>